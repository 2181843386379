.flex.jus {
    justify-content: space-between;
}

.jus p {
    text-transform: uppercase;
    margin: 0;
}

.max-fot {
    max-width: 300px;
}

.logo-footer img {
    width: 120px;
    padding-block: 10px;
}

@media (max-width:768px) {
    .flex.jus {
        flex-direction: column;
        text-align: center;
        padding-block: 40px;
    }

}