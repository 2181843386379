.contact-sec {
    padding-top: 30px;
    background-color: rgb(243, 243, 243);
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.contact-sec h1 {
    color: #59ada1;
}

.contact-sec a {
    color: white;
    margin-top: 10px;
    background-color: #59ada1;
    padding: 10px 20px;
    border: 1px solid #59ada1;
    text-decoration: none;
    border-radius: 10px;
    transition: all 0.3s ease;
}

.contact-sec a:hover {
    background-color: white;
    color: #59ada1;
    transition: all 0.3s ease;
}