 .coa {
     max-width: 1000px !important;
 }

 .flex-two {
     display: flex;
     width: 100%;
     gap: 30px;
     margin-bottom: 10px;
 }

 .each-diva {

     position: relative;
 }

 .each-diva {
     width:
         50%;
     text-align: left;
 }

 .each-diva input {
     box-sizing: border-box;

     outline: none;

 }

 .coa input {
     margin-bottom: 5px;
 }

 .lofa {
     color: black;
     font-weight: 600;
     text-decoration: none;
 }

 .eye {
     position: absolute;
     right: 20px;
     cursor: pointer;
     top: 33px
 }

 .reg-btn:disabled {
     opacity: 0.3;
     cursor: default;

 }

 .text-danger {
     color: red;
     font-size: 15px;
 }

 .eye.style-1 {
     left: 25px;
 }

 .contact-form input, .contact-form textarea {
     box-sizing: border-box;
 }

 @media (max-width: 768px) {
     .flex-two {
         flex-direction: column;
         gap: 0;
         margin-bottom: 0;
     }

     .each-diva {
         width: 100%;
     }

 }