.about-us {

    color: #333;
}

/* Hero Section */
.hero {
    text-align: center;
    /* background-color: #f4f4f4; */
    padding: 20px 20px;
}

.hero h1 {
    font-size: 3em;
    margin-bottom: 10px;
}

.hero p {
    font-size: 1.2em;
    color: #666;
}

/* About Section */
/* .about-section {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 50px 20px;
    flex-wrap: wrap;
} */

.about-content {
    flex: 1;
    padding-bottom: 50px;
}

.about-content h2 {
    font-size: 1.7em;
    font-weight: 600;
    margin-bottom: 0px;
}

.about-content p {
    margin-top: 10px;
    font-size: 1em;
    line-height: 1.6;

    margin-bottom: 40px;
}

.about-image {
    flex: 1;
}

.about-image img {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
}

/* Values Section */
.values-section {
    text-align: center;
    padding: 50px 20px;
    background-color: #f9f9f9;
}

.values {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
}

.value {
    flex: 1;
    min-width: 200px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.value h3 {
    margin-bottom: 10px;
    font-size: 1.5em;
    color: #333;
}

/* Team Section */
.team-section {
    text-align: center;
    padding: 50px 20px;
}

.team {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
}

.team-member {
    width: 200px;
    text-align: center;
}

.team-member img {
    width: 100%;
    border-radius: 50%;
    margin-bottom: 10px;
}

.team-member h3 {
    font-size: 1.2em;
    margin: 0;
}

/* Contact Section */
.contact-section {
    text-align: center;
    padding: 50px 20px;
    background-color: #f4f4f4;
}

.contact-section h2 {
    font-size: 2em;
    margin-bottom: 10px;
}

.contact-section p {
    font-size: 1.1em;
    margin-bottom: 20px;
}

.contact-button {
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 1.1em;
}

.contact-button:hover {
    background-color: #555;
}

/* Responsive Styling */
@media (max-width: 768px) {
    .about-section {
        flex-direction: column;
        text-align: center;
    }

    .about-image,
    .about-content {
        width: 100%;
    }

    .values {
        flex-direction: column;
    }

    .team {
        flex-direction: column;
    }
}