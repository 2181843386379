.contact-us {

    color: #333;
    line-height: 1.6;
}

/* Hero Section */
.contact-hero {
    text-align: center;
    background-color: #f4f4f4;
    padding: 50px 20px;
}


.lea input {
    margin-bottom: 5px !important;
    box-sizing: border-box;
}

.lea {
    text-align: left;
    justify-content: center !important;
    align-items: stretch !important;
    text-align: left !important;
}

.contact-hero h1 {
    font-size: 3em;
    margin-bottom: 10px;
}

.contact-hero p {
    font-size: 1.2em;
    color: #666;
}

/* Contact Info Section */
.contact-info {
    display: flex;
    gap: 20px;
    padding: 50px 20px;
    flex-wrap: wrap;
}

.contact-details {
    flex: 1;
    max-width: 500px;
}

.contact-details h2 {
    font-size: 2em;
    margin-bottom: 15px;
}

.map {
    flex: 1;
    max-width: 500px;
}

.map iframe {
    width: 100%;
    height: 300px;
    border: none;
    border-radius: 8px;
}

/* Contact Form Section */
.contact-form-section {
    text-align: center;
    padding: 50px 20px;
    background-color: #f9f9f9;
}

.contact-form-section h2 {
    font-size: 2em;
    margin-bottom: 20px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    margin: auto;
}

.contact-form label {
    align-self: flex-start;
    margin: 10px 0 5px;
    font-weight: bold;
    font-size: 1em;
}

.contact-form input, .contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1em;
}

.contact-form button {
    background-color: #59ada1;
    color: #fff;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1.1em;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.contact-form button:hover {
    background-color: #359385;
}

/* Responsive Styling */
@media (max-width: 768px) {
    .contact-info {
        flex-direction: column;
        text-align: center;
    }

    .contact-details, .map {
        max-width: 100%;
    }

    .contact-hero h1 {
        font-size: 2.5em;
    }

    .contact-hero p {
        font-size: 1.1em;
    }
}