/* CuisinePage.css */

.cuisine-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;

    padding-bottom: 50px !important;
}

textarea {
    resize: none !important;
}

.form-fields input {
    outline: none;
}

.cuisine-page h1 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #333;
}


.menu-section {
    margin-bottom: 1.5rem;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.menu-section h2 {
    font-size: 1.5rem;
    color: #555;
    margin-bottom: 0.5rem;
}

.menu-section ul {
    list-style: none;
    padding: 0;
}

.menu-section li {
    margin-bottom: 0.5rem;
}

.menu-section label {
    font-size: 1rem;
    color: #333;
}

.menu-section input[type="checkbox"] {
    margin-right: 10px;
}

/* CuisinePage.css */

.cuisine-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;

}

.cuisine-page h1 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #333;
}

/* 
.menu-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 3fr));
    gap: 1.5rem;
}

.menu-category {
    text-align: center;
    position: relative;
    cursor: pointer;
    padding: 1rem;
    transition: all 0.3s ease;
    border: 3px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.img-area {
    width: 100%;
    height: 150px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: auto;
    border-radius: 8px;
}

.category-image {
    border-radius: 8px;
    width: 100%;
    transition: transform 0.3s;
}

.category-image:hover {
    transform: scale(1.05);
}

.category-title {
    margin-top: 0.5rem;
    font-size: 1.2rem;
    color: #555;
}

.checkbox-container {
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.category-checkbox {
    margin-right: 0.5rem;
} */
.menu-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
}

.menu-category {
    width: calc(33.33% - 20px);
    /* Three columns */
    box-sizing: border-box;
    font-size: 16px;
}

.menu-category label {
    display: flex;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
}

.menu-checkbox input {
    margin-right: 10px;
    transform: scale(1.2);
    /* Make the checkbox slightly larger */
}

.menu-category input:checked+span {
    font-weight: bold;
    color: #4CAF50;
    /* Optional: highlight checked items */
}

.tray-selection {
    margin-top: 0px;
}

.tray-selection h3 {
    font-size: 18px;
    margin-bottom: 10px;
    margin-top: 0;
}

.form-fields .tray-option input {
    width: max-content;

}

.form-fields .tray-option p {
    margin: 0;
    min-width: 100px;
}

.form-fields .tray-option {
    /* display: flex; */
    font-size: 16px;
    margin-bottom: 10px;
    gap: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: row-reverse;
    width: -webkit-fill-available;
}

.tray-option input {
    margin-right: 10px;
    transform: scale(1.2);
    /* Optional: enlarge the radio button for better visibility */
}

.view-menu-button {
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    color: #fff;
    background-color: #59ada1;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.view-menu-button:hover {
    background-color: #59ada1;
}

.form-fields {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-fields label {
    font-size: 1rem;
    color: #333;
}

.isal:disabled {
    cursor: not-allowed;
    opacity: 0.5
}

.error-message {
    color: red;
}

.form-fields input,
.form-fields textarea {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

.submit-button {
    margin-top: 1.5rem;
    padding: 0.75rem 1.5rem;
    font-size: 1.2rem;
    color: #fff;
    background-color: #59ada1;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s;
}

.submit-button:hover {
    background-color: #3e9b8d;
}

.selected {
    transition: all 0.3s ease;

    border: 3px solid #59ada1;
}

/* Success Message Styling */
.success-message {
    text-align: center;
    padding: 50px 20px;
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
    border-radius: 8px;
    margin-top: 30px;
}

.success-message h2 {
    font-size: 24px;
    color: #155724;
    margin-bottom: 10px;
}

.success-message p {
    font-size: 18px;
    color: #155724;
}

.success-message button {
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    margin-top: 20px;
}

.success-message button:hover {
    background-color: #218838;
}

@media (max-width:600px) {
    .menu-category {
        width: 40%;
    }

    .menu-grid {
        justify-content: center;
    }

}

@media (max-width:400px) {
    .menu-category {
        width: 100%;
    }

    .menu-grid {
        justify-content: start;
    }

}